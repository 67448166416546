// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BhajansWebsite from './components/BhajansWebsite';
import TermsAndConditions from './components/terms';
import PrivacyPolicy from './components/privacy';

function App() {
  return (
    <Routes>
      <Route path="/" element={<BhajansWebsite />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;