import React, { useState, useRef, useEffect, createContext, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  Play, Pause, SkipBack, SkipForward, Volume2, VolumeX, Search,
  Maximize2, ListMusic, Info, Repeat, Shuffle, X, Loader2, AlertTriangle
} from 'lucide-react';
import * as GA from './googleanalytics';
import Cookies from 'js-cookie';

import { motion } from 'framer-motion';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import { auth, googleProvider } from './firebase';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOm, faStar } from '@fortawesome/free-solid-svg-icons';

const ThemeContext = createContext();

const themes = {
  default: {
    primary: '#f97316',
    secondary: '#3b82f6',
    background: '#fffbeb',
    text: '#1f2937',
  },
  dark: {
    primary: '#f59e0b',
    secondary: '#60a5fa',
    background: '#1f2937',
    text: '#f3f4f6',
  },
  nature: {
    primary: '#10b981',
    secondary: '#3b82f6',
    background: '#ecfdf5',
    text: '#1f2937',
  },
};

const getYouTubeThumbnail = (url) => {
  try {
    const urlObj = new URL(url);
    let videoId;
    if (urlObj.hostname === "youtu.be") {
      videoId = urlObj.pathname.slice(1);
    } else {
      videoId = urlObj.searchParams.get("v");
    }
    return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : "";
  } catch (error) {
    return "";
  }
};

const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState('default');

  useEffect(() => {
    document.documentElement.style.setProperty('--color-primary', themes[currentTheme].primary);
    document.documentElement.style.setProperty('--color-secondary', themes[currentTheme].secondary);
    document.documentElement.style.setProperty('--color-background', themes[currentTheme].background);
    document.documentElement.style.setProperty('--color-text', themes[currentTheme].text);
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const ThemeSwitcher = () => {
  const { currentTheme, setCurrentTheme } = useContext(ThemeContext);

  return (
    <div className="flex space-x-2">
      {Object.keys(themes).map((theme) => (
        <button
          key={theme}
          onClick={() => setCurrentTheme(theme)}
          className={`w-8 h-8 rounded-full ${currentTheme === theme ? 'ring-2 ring-offset-2 ring-gray-400' : ''}`}
          style={{ backgroundColor: themes[theme].primary }}
        ></button>
      ))}
    </div>
  );
};

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const AudioVisualizer = ({ audioRef }) => {
  const canvasRef = useRef(null);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  useEffect(() => {
    if (isMobileDevice || !audioRef.current) return;

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaElementSource(audioRef.current);
    source.connect(analyser);
    analyser.connect(audioContext.destination);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const draw = () => {
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.getByteFrequencyData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / bufferLength) * 2.5;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i] / 2;
        ctx.fillStyle = `rgb(${barHeight + 100}, 50, 50)`;
        ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }

      animationFrameId = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      cancelAnimationFrame(animationFrameId);
      source.disconnect();
    };
  }, [audioRef, isMobileDevice]);

  if (isMobileDevice) {
    return null;
  }

  return <canvas ref={canvasRef} className="w-full h-24 md:h-32" />;
};

const formatDuration = (duration) => {
  if (isNaN(duration) || duration <= 0) return '0:00';
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const maskEmail = (email) => {
  if (!email) return '';
  const [localPart, domain] = email.split('@');
  const maskedLocalPart = `${localPart.slice(0, 2)}***${localPart.slice(-1)}`;
  const domainParts = domain.split('.');
  const maskedDomain = `${domainParts[0][0]}***`;
  return `${maskedLocalPart}@${maskedDomain}.${domainParts.slice(1).join('.')}`;
};

const formatBhajanName = (name) => {
  if (!name) return '';
  const firstBracketIndex = name.indexOf('(');
  if (firstBracketIndex === -1) {
    return toCamelCase(name);
  }
  const mainName = name.substring(0, firstBracketIndex).trim();
  const rest = name.substring(firstBracketIndex);
  const formattedName = `${toCamelCase(mainName)} ${rest}`;
  return formattedName;
};

const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/ /g, ' ');
};

// Helper function to build array of up to six videos from metadata
const buildSixVideosArray = (metadata) => {
  if (!metadata || !Array.isArray(metadata)) return [];
  return metadata.slice(0, 6).map(video => ({
    url: video.url,
    title: video.video_title,
    thumbnail: getYouTubeThumbnail(video.url)
  }));
};

const BhajansWebsite = () => {
  const location = useLocation();
  const [userTracks, setUserTracks] = useState(0);
  const [isProgressDialogOpen, setIsProgressDialogOpen] = useState(false);
  const [playAfterInteraction, setPlayAfterInteraction] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(50);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [tracks, setTracks] = useState([]);
  const [showTracks, setShowTracks] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [language, setLanguage] = useState('EN');
  const [generatedTrackUrl, setGeneratedTrackUrl] = useState('');
  const [generatedTrackUrl2, setGeneratedTrackUrl2] = useState('');
  const [creationDuration, setcreationDuration] = useState('');
  const [generatedTrackName1, setGeneratedTrackName1] = useState('');
  const [generatedTrackName2, setGeneratedTrackName2] = useState('');
  const [generatedTrackGenre1, setGeneratedTrackGenre1] = useState('');
  const [generatedTrackGenre2, setGeneratedTrackGenre2] = useState('');
  const [generatedTrackInstruments1, setGeneratedTrackInstruments1] = useState('');
  const [generatedTrackInstruments2, setGeneratedTrackInstruments2] = useState('');
  const [quotaExceeded, setQuotaExceeded] = useState(false);

  const [selectedStyles, setSelectedStyles] = useState([]);
  const [bhajanDescription, setBhajanDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationMessage, setGenerationMessage] = useState('');
  const [generationProgress, setGenerationProgress] = useState(0);
  const audioRef = useRef(null);
  const [repeat, setRepeat] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const [selectedBhajan, setSelectedBhajan] = useState('');
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [selectedInstrument, setSelectedInstrument] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [likes, setLikes] = useState(0);
  const [views, setViews] = useState(0);
  const scrollRefs = useRef([]);
  const [expanded, setExpanded] = useState({});
  const [expandedTracks, setExpandedTracks] = useState({});
  const [totalPlayedTime, setTotalPlayedTime] = useState(0);
  const [bpm, setBpm] = useState(80);
  const [selectedVocals, setVocals] = useState('Male');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [totalViews, setTotalViews] = useState(0);
  const [topSongs, setTopSongs] = useState([]);

  const [showGurujiForm, setShowGurujiForm] = useState(false);
  const [showStarsForm, setShowStarsForm] = useState(false);

  const [gurujiQuestion, setGurujiQuestion] = useState('');
  const [starsQuestion, setStarsQuestion] = useState('');
  const [locationInput, setLocationInput] = useState('');

  // For suggestions
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const [astroLoading, setAstroLoading] = useState(false);
  const [astroResponse, setAstroResponse] = useState(null);
  const [astroModalOpen, setAstroModalOpen] = useState(false);
  const [astroProgressText, setAstroProgressText] = useState('');

  const [gurujiLoading, setGurujiLoading] = useState(false);
  const [gurujiResponse, setGurujiResponse] = useState(null);
  const [gurujiModalOpen, setGurujiModalOpen] = useState(false);
  const [gurujiProgressText, setGurujiProgressText] = useState('');

  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
  const [hasSeenWelcomeDisclaimer, setHasSeenWelcomeDisclaimer] = useState(
    () => Cookies.get('hasSeenWelcomeDisclaimer') === 'true'
  );

  const [isVideoHovered, setIsVideoHovered] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [isVideoFullscreen, setIsVideoFullscreen] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    GA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (!hasSeenWelcomeDisclaimer) {
      setDisclaimerModalOpen(true);
      Cookies.set('hasSeenWelcomeDisclaimer', 'true', { expires: 365 });
      setHasSeenWelcomeDisclaimer(true);
    }
  }, []);

  const handleSkipBack = () => {
    setCurrentTrack((prev) => {
      const newTrack = (prev - 1 + filteredTracks.length) % filteredTracks.length;
      scrollToTrack(newTrack);
      return newTrack;
    });
  };

  const handleSkipForward = () => {
    setCurrentTrack((prev) => {
      const newTrack = (prev + 1) % filteredTracks.length;
      scrollToTrack(newTrack);
      return newTrack;
    });
  };

  const scrollToTrack = (index) => {
    const container = document.getElementById('playlist-container');
    const trackElement = scrollRefs.current[index];
    if (container && trackElement) {
      const containerRect = container.getBoundingClientRect();
      const trackRect = trackElement.getBoundingClientRect();
      if (trackRect.top < containerRect.top || trackRect.bottom > containerRect.bottom) {
        container.scrollTop = trackElement.offsetTop - container.offsetTop;
      }
    }
  };

  useEffect(() => {
    scrollToTrack(currentTrack);
  }, [currentTrack]);

  const fetchUserTracks = async (userEmail) => {
    try {
      const response = await fetch(`/api/user-tracks?email=${userEmail}`);
      const data = await response.json();
      setUserTracks(data.totalTracks);
    } catch (error) {
      console.error('Error fetching user tracks:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserTracks(user.email);
    }
  }, [user]);

  const fetchTotalViews = async () => {
    try {
      const response = await fetch('/api/total-views');
      const data = await response.json();
      setTotalViews(data.totalViews);
    } catch (error) {
      console.error('Error fetching total views:', error);
    }
  };

  useEffect(() => {
    fetchTotalViews();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    try {
      setIsLoggingIn(true);
      const result = await auth.signInWithPopup(googleProvider);
      setUser(result.user);
      GA.event({
        action: 'login',
        category: 'User',
        label: 'Google Login',
      });
    } catch (error) {
      console.error('Error during login:', error);
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const [tracksLoaded, setTracksLoaded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timeoutId = setTimeout(() => setIsLoading(false), 5000);
    fetch('/api/tracks')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          const sortedTracks = data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          const indexedTracks = sortedTracks.map((track, index) => ({
            ...track,
            index,
          }));
          setTracks(indexedTracks);
          setCurrentTrack(0);
          setLikes(indexedTracks[0].likes || 0);
          setViews(indexedTracks[0].views || 0);
          setTotalPlayedTime(indexedTracks[0].total_played_time || 0);
        }
        setTracksLoaded(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching tracks:', error);
        setIsLoading(false);
      });
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const fetchTopSongs = async () => {
      try {
        const response = await fetch('/api/top-favorite-songs');
        const data = await response.json();
        setTopSongs(data);
      } catch (error) {
        console.error('Error fetching top favorite songs:', error);
      }
    };
    fetchTopSongs();
  }, []);

  useEffect(() => {
    if (tracks.length > 0 && currentTrack < tracks.length) {
      const track = tracks[currentTrack];
      setLikes(track.likes || 0);
      setViews(track.views || 0);
      setTotalPlayedTime(track.total_played_time || 0);
    }
  }, [currentTrack, tracks]);

  const handleLike = async (trackId) => {
    try {
      const response = await fetch('/api/like-track', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ trackId }),
      });
      const data = await response.json();
      if (data.success) {
        setTracks((prevTracks) =>
          prevTracks.map((track) => {
            if (track.id === trackId) {
              return { ...track, likes: (track.likes || 0) + 1 };
            }
            return track;
          })
        );
        if (tracks[currentTrack].id === trackId) {
          setLikes((prevLikes) => prevLikes + 1);
        }
      } else {
        console.error('Error liking track:', data.error);
      }
    } catch (error) {
      console.error('Error liking track:', error);
    }
  };

  const formattedTrack = tracks[currentTrack] || { name: '', artist: '', created_at: '' };

  useEffect(() => {
    if (audioRef.current && tracks.length > 0) {
      const audioUrl = `/audio/${tracks[currentTrack]?.filename}?player=true`;
      audioRef.current.src = audioUrl;
      audioRef.current.currentTime = 0;
      if (isPlaying) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
          setPlayAfterInteraction(true);
        });
      }
    }
  }, [currentTrack, tracks]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
          setPlayAfterInteraction(true);
        });
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const playParam = params.get('play');
    if (playParam && tracks.length > 0) {
      const trackIndex = tracks.findIndex(
        (track) => track.filename === `${playParam}.mp3`
      );
      if (trackIndex !== -1) {
        setCurrentTrack(trackIndex);
        setPlayAfterInteraction(true);
      }
    }
  }, [location.search, tracks]);

  useEffect(() => {
    if (playAfterInteraction) {
      const handleUserInteraction = () => {
        handlePlayClick();
        setPlayAfterInteraction(false);
        document.removeEventListener('click', handleUserInteraction);
      };
      document.addEventListener('click', handleUserInteraction);
      return () => {
        document.removeEventListener('click', handleUserInteraction);
      };
    }
  }, [playAfterInteraction]);

  const handlePlayClick = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
          setPlayAfterInteraction(true);
        });
        setIsPlaying(true);
        GA.event({
          action: 'play_bhajan',
          category: 'Bhajan',
          label: tracks[currentTrack]?.bhajan_name || 'Unknown',
        });
      }
    }
  };

  const PlayDialog = ({ onClose }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
          <h2 className="text-xl font-bold mb-4">Play Bhajan</h2>
          <p className="mb-6">Please click the button below to play the Bhajan.</p>
          <button
            onClick={onClose}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Play Bhajan
          </button>
        </div>
      </div>
    );
  };

  const handleTimeUpdate = () => {
    if (audioRef.current && !isNaN(audioRef.current.duration)) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
      updateSeekBarBackground(audioRef.current.currentTime, audioRef.current.duration);
    }
  };

  const updateSeekBarBackground = (currentTime, duration) => {
    const seekBar = document.getElementById('seek-bar');
    const value = (currentTime / duration) * 100;
    seekBar.style.background = `linear-gradient(to right, orange ${value}%, gray ${value}%)`;
  };

  const handleSeek = (e) => {
    const time = parseFloat(e.target.value);
    if (audioRef.current) {
      audioRef.current.currentTime = time;
      setCurrentTime(time);
    }
    updateSeekBarBackground(time, duration);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : volume / 100;
    }
  }, [volume, isMuted]);

  const handleVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100;
    }
    setIsMuted(newVolume === 0);
  };

  const recordPlay = async (trackId, duration) => {
    await fetch('/api/record-play', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ trackId, duration }),
    });
  };

  const updatePlayedDuration = async (trackId, duration) => {
    await fetch('/api/update-played-duration', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ trackId, duration }),
    });
  };

  const toggleFullScreen = () => {
    const playerContainer = document.getElementById('player-container');
    if (!document.fullscreenElement) {
      playerContainer.requestFullscreen().catch((err) => console.error(err));
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const stopPlayer = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setCurrentTime(0);
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'EN' ? 'HI' : 'EN'));
  };

  const toggleCategory = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  const filteredTracks = tracks.filter((track) => {
    const matchesSearch =
      searchTerm === '' ||
      (track.bhajan_name &&
        track.bhajan_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (track.genre && track.genre.toLowerCase().includes(searchTerm.toLowerCase()));
    const matchesCategory =
      !selectedCategory ||
      (track.bhajan_name && track.bhajan_name.startsWith(selectedCategory));
    return matchesSearch && matchesCategory;
  });

  const categorizedTracks = filteredTracks.reduce((acc, track, index) => {
    const category = track.bhajan_name.split(' ')[0];
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({ ...track, index });
    return acc;
  }, {});

  const handleGenreChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      if (selectedGenre.length < 4) {
        setSelectedGenre((prev) => [...prev, value]);
      } else {
        e.target.checked = false;
      }
    } else {
      setSelectedGenre((prev) => prev.filter((genre) => genre !== value));
    }
  };

  const handleInstrumentChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      if (selectedInstrument.length < 5) {
        setSelectedInstrument((prev) => [...prev, value]);
      } else {
        e.target.checked = false;
      }
    } else {
      setSelectedInstrument((prev) => prev.filter((instrument) => instrument !== value));
    }
  };

  const toggleRepeat = () => {
    setRepeat(!repeat);
  };

  const toggleShuffle = () => {
    setShuffle(!shuffle);
  };

  const getRandomTrackIndex = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * tracks.length);
    } while (randomIndex === currentTrack);
    return randomIndex;
  };

  const handleVocalsChange = (e) => {
    setVocals(e.target.value);
  };

  const totalTracks = tracks.length;

  const GeneratedTracksDialog = ({
    isOpen,
    onClose,
    trackUrl1,
    trackUrl2,
    trackName1,
    trackName2,
    trackGenre1,
    trackGenre2,
    trackInstruments1,
    trackInstruments2,
    creationDuration,
  }) => {
    if (!isOpen) return null;

    const shareUrl1 = `${window.location.origin}${trackUrl1}`;
    const shareUrl2 = `${window.location.origin}${trackUrl2}`;
    const shareText1 = `Check out this bhajan: ${trackName1} - Genre: ${trackGenre1} - Instruments: ${trackInstruments1}`;
    const shareText2 = `Check out this bhajan: ${trackName2} - Genre: ${trackGenre2} - Instruments: ${trackInstruments2}`;

    const handleClose = () => {
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 100);
    };

    const openTrackInNewWindow = (trackUrl) => {
      window.open(trackUrl, '_blank');
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full overflow-y-auto">
          <h3 className="text-2xl font-bold mb-6 text-gray-800">
            Generated Tracks in {creationDuration} mins
          </h3>
          {/* Track 1 */}
          <div className="mb-6">
            <p className="text-lg text-gray-800 font-semibold">{trackName1}</p>
            <p className="text-gray-600">Genre: {trackGenre1}</p>
            <p className="text-gray-600 mb-2">Instruments: {trackInstruments1}</p>

            <button
              onClick={() => openTrackInNewWindow(trackUrl1)}
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mt-2 mb-4"
            >
              Play Track 1
            </button>
            <div className="flex items-center space-x-2 mb-4">
              <WhatsappShareButton url={shareUrl1} title={shareText1}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <FacebookShareButton url={shareUrl1} quote={shareText1}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl1} title={shareText1}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>

          {/* Track 2 */}
          <div className="mb-6">
            <p className="text-lg text-gray-800 font-semibold">{trackName2}</p>
            <p className="text-gray-600">Genre: {trackGenre2}</p>
            <p className="text-gray-600 mb-2">Instruments: {trackInstruments2}</p>
            <button
              onClick={() => openTrackInNewWindow(trackUrl2)}
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mt-2 mb-4"
            >
              Play Track 2
            </button>
            <div className="flex items-center space-x-2 mb-4">
              <WhatsappShareButton url={shareUrl2} title={shareText2}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <FacebookShareButton url={shareUrl2} quote={shareText2}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl2} title={shareText2}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>

          <button
            onClick={handleClose}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-4"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const ProgressDialog = ({ isOpen, progress, message }) => {
    if (!isOpen) return null;
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl shadow-2xl p-8 max-w-md w-full text-center">
          <CircularProgressbar
            value={progress}
            text={`${Math.round(progress)}%`}
            styles={buildStyles({
              textColor: '#4F46E5',
              pathColor: '#4F46E5',
              trailColor: '#E5E7EB',
            })}
            className="w-24 h-24 mx-auto"
          />
          <div className="mt-4">
            <p className="text-lg font-semibold text-gray-800">{message}</p>
          </div>
        </div>
      </div>
    );
  };

  const QuotaExceededMessage = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center">
        <h3 className="text-2xl font-bold mb-4 text-red-600">Daily Quota Exceeded</h3>
        <p className="mb-6 text-gray-700">
          You've reached your daily limit for creating bhajans. Please try again tomorrow!
        </p>
        <button
          onClick={onClose}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );

  const safeRender = (value, maxLength = 500) => {
    try {
      if (value === null || value === undefined) return 'Not available';
      let str = typeof value === 'object' ? JSON.stringify(value) : String(value);
      str = str.replace(/[<>]/g, '').replace(/^\s+|\s+$/g, '');
      if (str.length > maxLength) {
        const truncated = str.substring(0, maxLength);
        const lastSpace = truncated.lastIndexOf(' ');
        return lastSpace > 0 ? truncated.substring(0, lastSpace) + '...' : truncated + '...';
      }
      return str;
    } catch (e) {
      console.error('Error in safeRender:', e);
      return 'Error displaying value';
    }
  };

  const safeGet = (obj, path, defaultValue = 'Not available') => {
    if (!obj || typeof obj !== 'object') {
      //console.debug(`safeGet: Provided value is not an object:`, obj);
      return defaultValue;
    }
    const parts = path.split('.');
    let current = obj;
    for (const part of parts) {
      const lowerPart = part.toLowerCase();
      //console.debug(`safeGet: Looking for key "${lowerPart}" in:`, Object.keys(current));
      const matchingKey = Object.keys(current).find(
        key => key.toLowerCase() === lowerPart
      );
      if (!matchingKey) {
        //console.warn(`safeGet: No matching key for "${part}" in object:`, current);
        return defaultValue;
      }
      current = current[matchingKey];
    }
    //console.debug(`safeGet: Found value for path "${path}":`, current);
    return current;
  };

  const handleGenerateBhajan = async () => {
    if (!user) {
      alert('You need to be logged in to generate a bhajan.');
      return;
    }
    if (!selectedBhajan || selectedGenre.length === 0 || selectedInstrument.length === 0) {
      alert('Please select a Bhajan, at least one genre, and at least one instrument.');
      return;
    }

    GA.event({
      action: 'generate_bhajan',
      category: 'Bhajan',
      label: selectedBhajan,
    });

    try {
      const res = await fetch('/api/check-daily-limit', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userEmail: user.email }),
      });
      const data = await res.json();
      if (data.limitReached) {
        setQuotaExceeded(true);
        return;
      }
    } catch (error) {
      console.error('Error checking daily limit:', error);
      alert('An error occurred. Please try again.');
      return;
    }

    setIsGenerating(true);
    setGenerationProgress(0);
    setGenerationMessage('');
    setIsProgressDialogOpen(true);

    const params = new URLSearchParams({
      bhajanName: selectedBhajan,
      selectedGenre: selectedGenre.join(','),
      selectedInstrument: selectedInstrument.join(','),
      bpm,
      vocals: selectedVocals,
      userName: user.displayName,
      userEmail: user.email,
    }).toString();

    const eventSource = new EventSource(`/api/generate-custom-bhajan?${params}`);

    eventSource.onmessage = (event) => {
      const d = JSON.parse(event.data);
      if (d.error) {
        alert(d.error);
        setIsGenerating(false);
        setIsProgressDialogOpen(false);
        eventSource.close();
        return;
      }

      setGenerationProgress(d.progress);
      setGenerationMessage(d.message);

      if (d.progress === 100) {
        setIsGenerating(false);
        setIsProgressDialogOpen(false);
        setGenerationMessage('Track created successfully');

        setGeneratedTrackUrl(d.trackUrl1);
        setGeneratedTrackUrl2(d.trackUrl2);
        setcreationDuration(d.creationDuration);
        setGeneratedTrackName1(d.trackName1);
        setGeneratedTrackName2(d.trackName2);
        setGeneratedTrackGenre1(d.trackGenre1);
        setGeneratedTrackGenre2(d.trackGenre2);
        setGeneratedTrackInstruments1(d.trackInstruments1);
        setGeneratedTrackInstruments2(d.trackInstruments2);

        setIsDialogOpen(true);
        eventSource.close();
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      setIsGenerating(false);
      setIsProgressDialogOpen(false);
      eventSource.close();
    };
  };

  function normalizeKeys(obj) {
    if (Array.isArray(obj)) {
      return obj.map(normalizeKeys);
    }
    if (obj && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const normalizedKey = key.trim().toLowerCase().replace(/\s+/g, '_');
        acc[normalizedKey] = normalizeKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  }

  const handleLocationChange = async (value) => {
    setLocationInput(value);
  
    // If input is too short or blank, clear suggestions
    if (value.trim().length < 3) {
      setLocationSuggestions([]);
      return;
    }
  
    try {
      // We limit results to 5 by appending &limit=5
      // If you want to pass user’s lat/lon for better local results,
      // you can add &lat=52.3879&lon=13.0582, for example
      const url = `https://photon.komoot.io/api/?q=${encodeURIComponent(value)}&limit=5`;
      const response = await fetch(url);
      const data = await response.json();
  
      // data is GeoJSON FeatureCollection. The actual info is in `data.features`
      // We map over data.features and build our suggestions
      if (data && data.features) {
        // Build suggestions in a format your UI can display
        const suggestions = data.features.map((feature) => {
          const { coordinates } = feature.geometry; // [lon, lat]
          const { name, country, city, state, postcode } = feature.properties;
  
          // You can define how you want to display the location
          // For example: "Berlin, Germany" or "Berlin (city), Germany"
          // Or combine name, city, state, country, etc.
          const displayNameParts = [];
          if (name) displayNameParts.push(name);
          if (city) displayNameParts.push(city);
          if (state) displayNameParts.push(state);
          if (country) displayNameParts.push(country);
  
          // Join them with comma + space
          const displayName = displayNameParts.join(', ');
  
          // Return an object that your UI can use
          return {
            display_name: displayName,
            lat: coordinates[1], // lat is second item in [lon, lat]
            lon: coordinates[0], // lon is first item
            rawProperties: feature.properties,
            postcode: postcode,
          };
        });
  
        setLocationSuggestions(suggestions);
      } else {
        setLocationSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching location suggestions from Photon:', error);
      setLocationSuggestions([]);
    }
  };
  
// Once the user selects one of the suggestions
const handleSelectLocation = (suggestion) => {
  // We'll store the constructed display_name from Photon
  setLocationInput(suggestion.display_name);

  // Or pass lat, lon, and user-friendly name to your selectedLocation
  setSelectedLocation({
    lat: suggestion.lat,
    lon: suggestion.lon,
    displayName: suggestion.display_name,
  });

  // Clear suggestions from the UI
  setLocationSuggestions([]);
};


  const handleAstroSubmit = async () => {
    if (!user) {
      alert('You need to be logged in to ask the stars.');
      return;
    }
    if (!starsQuestion.trim()) {
      alert('Please enter your question');
      return;
    }
    if (!selectedLocation) {
      alert('Please enter/select your location from the list of suggestions.');
      return;
    }

    setAstroLoading(true);
    setAstroProgressText('');
    setAstroResponse(null);


// Gather location / lat-lon if selected
const locationData = selectedLocation
? { 
    lat: selectedLocation.lat, 
    lon: selectedLocation.lon, 
    displayName: selectedLocation.displayName 
  }
: null;


console.log('User location:', selectedLocation);

    let randomIndex = 0;
    const intervalId = setInterval(() => {
      randomIndex = (randomIndex + 1) % randomProgressTextArray.length;
      setAstroProgressText(randomProgressTextArray[randomIndex]);
    }, 1800);

    try {
      const response = await fetch('/api/astro-query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: starsQuestion,
          userEmail: user.email,
          location: locationData,
          service: {
            name: 'astro',
            query: starsQuestion,
            location: locationData,
          },
        }),
      });

      const data = await response.json();
      //console.debug("Raw astro response:", data);

      // If the response is an array and the first element is a JSON string, parse it first.
      if (Array.isArray(data)) {
        try {
          const [analysisStr, planetaryData] = data;
          const analysis = typeof analysisStr === 'string' ? JSON.parse(analysisStr) : analysisStr;
          
          const normalizedAnalysis = normalizeKeys(analysis);
          const normalizedPlanetaryData = normalizeKeys(planetaryData);

          // Combine the analysis and planetary data
          const normalizedResponse = {
        ...normalizedAnalysis,
        planetary_positions: normalizedPlanetaryData,
      };
          
          //console.debug("Normalized astro response:", normalizedResponse);
          setAstroResponse(normalizedResponse);
        } catch (e) {
          console.error("Failed to parse astro response:", e);
          setAstroResponse({ error: 'Failed to parse the response' });
        }
      } else {
        const normalizedData = normalizeKeys(data);
        setAstroResponse(normalizedData);
      }

      clearInterval(intervalId);
      setAstroLoading(false);
      setAstroProgressText('');
      setAstroModalOpen(true);
      setShowStarsForm(false);
      setStarsQuestion('');
    } catch (err) {
      clearInterval(intervalId);
      setAstroLoading(false);
      setAstroProgressText('');
      setAstroResponse({ error: 'Something went wrong. Please try again.' });
      setAstroModalOpen(true);
      setShowStarsForm(false);
      console.error(err);
    }
  };

  const handleGurujiSubmit = async () => {
    if (!user) {
      alert('You need to be logged in to ask the stars.');
      return;
    }

    if (!gurujiQuestion.trim()) {
      alert('Please enter your question');
      return;
    }
    setGurujiLoading(true);
    setGurujiProgressText('');
    setGurujiResponse(null);
    let randomIndex = 0;
    const intervalId = setInterval(() => {
      randomIndex = (randomIndex + 1) % randomProgressTextArrayForGuruji.length;
      setGurujiProgressText(randomProgressTextArrayForGuruji[randomIndex]);
    }, 1800);

    try {
      const response = await fetch('/api/guruji', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: gurujiQuestion,
          userEmail: user.email,
          service: {
            name: 'guruji',
            query: gurujiQuestion,
          },
        }),
      });

      const data = await response.json();
      clearInterval(intervalId);
      setGurujiLoading(false);
      setGurujiProgressText('');

      if (data.error) {
        setGurujiResponse({ error: data.error });
      } else {
        setGurujiResponse(data);
      }
      setGurujiModalOpen(true);
      setShowGurujiForm(false);
      setGurujiQuestion('');
    } catch (err) {
      clearInterval(intervalId);
      setGurujiLoading(false);
      setGurujiProgressText('');
      setGurujiResponse({ error: 'Something went wrong. Please try again.' });
      setGurujiModalOpen(true);
      setShowGurujiForm(false);
      console.error(err);
    }
  };

  const randomProgressTextArray = [
    'Analyzing the cosmic vibrations...',
    'Consulting ancient scriptures...',
    'Tuning in to spiritual frequencies...',
    'Gathering star alignments...',
    'Invoking blessings from the Divine...',
    'Synchronizing with universal energies...',
  ];

  const randomProgressTextArrayForGuruji = [
    'Guruji is meditating...',
    'Guruji is similing at you...',
    'Guruji is listening to your question...',
    'Guruji is providing guidance...',
    'Guruji is sharing insights...',
  ];

  const AstroResponseModal = () => {
    if (!astroModalOpen) return null;

    if (astroLoading) {
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 animate-fadeIn">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-white rounded-lg shadow-lg p-8 max-w-2xl mx-auto text-center"
          >
            <div className="animate-spin mb-4">
              <Loader2 className="h-12 w-12 text-orange-500 mx-auto" />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Reading the Stars</h3>
            <p className="text-gray-600">{astroProgressText}</p>
          </motion.div>
        </div>
      );
    }

    if (!astroResponse || astroResponse.error) {
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-lg shadow-lg overflow-y-auto max-w-2xl mx-auto"
          >
            <div className="p-6">
              <h2 className="text-2xl font-bold text-red-500 mb-4 flex items-center gap-2">
                <span>⚠️</span> Astro Query Error
              </h2>
              <p className="text-gray-700 mb-4">
                {astroResponse && astroResponse.error
                  ? astroResponse.error
                  : 'An unexpected error occurred while reading the stars. Please try again.'}
              </p>
            </div>
            <div className="p-4 border-t border-gray-200 flex justify-end gap-4">
              <button
                onClick={() => {
                  setAstroModalOpen(false);
                  setShowStarsForm(true);
                }}
                className="text-gray-600 py-2 px-4 rounded hover:bg-gray-100"
              >
                Try Again
              </button>
              <button
                onClick={() => setAstroModalOpen(false)}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </motion.div>
        </div>
      );
    }

    const renderDetailedAnalysis = (analysisObj) => {
      if (typeof analysisObj === 'string') {
        return <p>{analysisObj}</p>;
      }
      // Otherwise, assume it's an object and iterate over sections.
      return Object.entries(analysisObj).map(([section, content]) => (
        <div key={section} className="mb-4">
          <h4 className="text-xl font-bold mb-2">{section.replace(/_/g, " ")}</h4>
          {typeof content === 'object' ? (
            Object.entries(content).map(([key, value]) => (
              <p key={key}><strong>{key}:</strong> {value}</p>
            ))
          ) : (
            <p>{content}</p>
          )}
        </div>
      ));
    };

    const renderMainSummary = (data) => {
      if (!data) return null;

      return (
        <div className="space-y-6 transition-all duration-300">
          {/* Short Answer and Analysis Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gradient-to-r from-orange-50 to-amber-50 rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-300"
          >
            <h3 className="text-2xl font-bold text-orange-600 mb-4">Quick Summary</h3>
            <div className="space-y-4">
              <div className="overflow-hidden">
                <p className="text-lg font-medium text-gray-800 break-words">
                  {safeRender(safeGet(data, 'short_answer'), 800)}
                </p>
                <p className="text-gray-600 mt-2 break-words">
                  {safeRender(safeGet(data, 'short_analysis'), 800)}
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-orange-100 px-4 py-2 rounded-lg overflow-hidden">
                  <span className="font-semibold text-orange-700">Focus Area: </span>
                  <span className="break-words">{safeRender(safeGet(data, 'focus_area'), 800)}</span>
                </div>
                <div className="bg-red-100 px-4 py-2 rounded-lg overflow-hidden">
                  <span className="font-semibold text-red-700">Challenges: </span>
                  <span className="break-words">{safeRender(safeGet(data, 'challenges'), 800)}</span>
                </div>
              </div>
              <div className="bg-blue-50 px-4 py-3 rounded-lg overflow-hidden">
                <span className="font-semibold text-blue-700">Rationale: </span>
                <span className="break-words">{safeRender(safeGet(data, 'rationale'), 1800)}</span>
              </div>
            </div>
          </motion.div>

          {/* Detailed Analysis Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-xl p-6 shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
          >
            <h3 className="text-2xl font-bold text-indigo-600 mb-4">Detailed Analysis</h3>
            <div className="space-y-4 overflow-hidden">
              <p className="text-gray-700 leading-relaxed break-words whitespace-pre-wrap">
                {renderDetailedAnalysis(safeGet(data, 'detailed_analysis', {}))}
              </p>
            </div>
          </motion.div>

          {/* Remedies Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-gradient-to-r from-green-50 to-emerald-50 rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-300"
          >
            <h3 className="text-2xl font-bold text-green-600 mb-4">Remedies</h3>
            <div className="space-y-4 overflow-hidden">
              <p className="text-gray-700 whitespace-pre-wrap break-words">
                {safeRender(safeGet(data, 'remedies'), 1500)}
              </p>
            </div>
          </motion.div>

          {/* Planets Effect Section */}
          {safeGet(data, 'planetary_positions') && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="bg-gradient-to-r from-purple-50 to-indigo-50 rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-300 mb-4"
            >
              <h3 className="text-2xl font-bold text-purple-600 mb-6 flex items-center gap-2">
                <span>🌟</span> Planetary Positions
              </h3>
              
              {/* Planetary Effects */}
              {safeGet(data, 'planets_effect') && (
                <div className="mb-6">
                  <h4 className="text-xl font-semibold text-purple-500 mb-3">Planetary Effects</h4>
                  {Object.entries(data.planets_effect).map(([planet, effect]) => (
                    <div key={planet} className="mb-3 bg-white p-4 rounded-lg shadow">
                      <h5 className="font-semibold text-purple-600 capitalize mb-2">{planet}</h5>
                      <p className="text-gray-700">{effect}</p>
                    </div>
                  ))}
                </div>
              )}

              {/* Planetary Positions */}
              {safeGet(data, 'planetary_positions') && (
                <div>
                  <h4 className="text-xl font-semibold text-purple-500 mb-3">Planetary Positions</h4>
                  {Object.entries(data.planetary_positions).map(([planet, details]) => (
                    <div key={planet} className="mb-3 bg-white p-4 rounded-lg shadow">
                      <h5 className="font-semibold text-purple-600 capitalize mb-2">{planet}</h5>
                      <pre className="whitespace-pre-wrap text-sm text-gray-700 font-mono">
                        {JSON.stringify(details, null, 2)}
                      </pre>
                    </div>
                  ))}
                </div>
              )}
            </motion.div>
          )}
        </div>
      );
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          className="bg-gray-50 rounded-xl shadow-2xl overflow-y-auto p-8 relative"
          style={{ width: '90%', maxWidth: '1400px', height: '90%', margin: '2% auto' }}
        >
          <button
            onClick={() => setAstroModalOpen(false)}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X size={24} />
          </button>
          <h2 className="text-3xl font-bold text-orange-600 mb-6 flex items-center gap-3">
            <span className="text-4xl">🔮</span>
            Astrological Analysis
          </h2>
          <div className="space-y-8">
            {Array.isArray(astroResponse) ? (
              <>
                {renderMainSummary(astroResponse[0])}
                {renderPlanetaryDetails(astroResponse[1])}
              </>
            ) : (
              renderMainSummary(astroResponse)
            )}
          </div>
        </motion.div>
      </div>
    );
  };

  const renderPlanetaryDetails = (planets) => {
    if (!planets || typeof planets !== 'object') return null;

    const planetKeys = ['Sun', 'Moon', 'Mars', 'Mercury', 'Jupiter', 'Venus', 'Saturn', 'Rahu', 'Ketu'];

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8 }}
        className="bg-white rounded-xl p-6 shadow-lg mt-6 hover:shadow-xl transition-shadow duration-300"
      >
        <h3 className="text-2xl font-bold text-blue-600 mb-6 flex items-center gap-2">
          <span>🌟</span> Planetary Positions
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {planetKeys.map((planet, index) => (
            <motion.div
              key={planet}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.1 }}
              className="group bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg p-4 shadow-sm hover:shadow-md transition-all duration-300"
            >
              <div className="relative">
                <div className="flex items-center gap-2 mb-3">
                  <span
                    className="text-2xl cursor-help"
                    title={`${planet} Symbol`}
                  >
                    {getPlanetSymbol(planet).symbol}
                  </span>
                  <h4 className="font-bold text-lg text-blue-700">{getPlanetSymbol(planet).name}</h4>
                </div>

                {/* Enhanced tooltip */}
                <div className="absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-200 bg-black text-white text-sm p-2 rounded -top-8 left-0 z-50 whitespace-nowrap">
                  {getPlanetSymbol(planet).name} in {safeGet(planets[planet], 'zodiac_sign_name', 'Unknown')}
                </div>
              </div>

              <div className="space-y-2">
                <div className="flex items-center gap-2 relative group/zodiac">
                  <span
                    className="text-xl cursor-help"
                    title={`${getZodiacSymbol(safeGet(planets[planet], 'zodiac_sign_name', 'Unknown')).name} Symbol`}
                  >
                    {getZodiacSymbol(safeGet(planets[planet], 'zodiac_sign_name', 'Unknown')).symbol}
                  </span>
                  <span className="text-gray-700">{getZodiacSymbol(safeGet(planets[planet], 'zodiac_sign_name', 'Unknown')).name}</span>

                  {/* Zodiac tooltip */}
                  <div className="absolute invisible group-hover/zodiac:visible opacity-0 group-hover/zodiac:opacity-100 transition-opacity duration-200 bg-black text-white text-sm p-2 rounded -top-8 left-0 z-50 whitespace-nowrap">
                    {getZodiacSymbol(safeGet(planets[planet], 'zodiac_sign_name', 'Unknown')).name} Symbol
                  </div>
                </div>

                <div className="text-gray-600">
                  House: {safeGet(planets[planet], 'house_number', 'Unknown')}
                </div>
                <div className="text-gray-600">
                  Nakshatra: {safeGet(planets[planet], 'nakshatra_name', 'Unknown')}
                </div>

                {safeGet(planets[planet], 'isRetro', '').toLowerCase() === 'true' && (
                  <div className="text-orange-600 font-medium flex items-center gap-2">
                    <span title="Retrograde Motion">⟲</span> Retrograde
                  </div>
                )}

                {/* Additional planetary details if available */}
                {safeGet(planets[planet], 'degrees') && (
                  <div className="text-gray-600">
                    Position: {safeGet(planets[planet], 'degrees', '0')}°
                  </div>
                )}
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    );
  };

  const GurujiResponseModal = () => {
    if (!gurujiModalOpen) return null;

    if (!gurujiResponse || gurujiResponse.error) {
      return (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 animate-fadeIn">
          <div
            className="bg-gradient-to-br from-red-600 to-pink-500 rounded-3xl shadow-2xl overflow-y-auto"
            style={{ width: '60%', height: '80%', margin: '5% auto' }}
          >
            <div className="p-8">
              <h2 className="text-3xl font-extrabold text-white mb-4 text-center">
                Guruji Query Error
              </h2>
              <p className="mb-6 text-gray-200 text-center">
                {gurujiResponse && gurujiResponse.error
                  ? gurujiResponse.error
                  : 'Unknown error occurred.'}
              </p>
            </div>
            <div className="p-4 border-t border-gray-300 text-center">
              <button
                onClick={() => setGurujiModalOpen(false)}
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-full transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      );
    }

    const { final_answer, explanation, metadata, original_query } = gurujiResponse;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 animate-fadeIn">
        <div
          className="relative bg-white rounded-3xl shadow-2xl overflow-y-auto p-8"
          style={{ width: '60%', height: '80%', margin: '5% auto' }}
        >
          <button
            onClick={() => setGurujiModalOpen(false)}
            className="absolute top-6 right-6 text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X size={28} />
          </button>
          <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-red-600 mb-6 text-center">
            To your query '{original_query}', Guruji Says
          </h2>
          <div className="space-y-6 mb-8">
            <div>
              <p className="text-gray-800 whitespace-pre-line leading-relaxed">
                {typeof final_answer === 'object' ? final_answer.content : final_answer}
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl text-purple-700 mb-2">Guruji's Rationale:</h3>
              <p className="text-gray-800 whitespace-pre-line leading-relaxed">
                {typeof explanation === 'object' ? explanation.content : explanation}
              </p>
            </div>
          </div>

          {metadata && metadata.length > 0 && (
            <div>
              <h3 className="text-2xl font-bold text-purple-800 mb-4 text-center">
                Related Videos
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {buildSixVideosArray(metadata).map((video, idx) => (
                  <a
                    key={idx}
                    href={video.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block"
                  >
                    <div className="bg-white rounded-xl overflow-hidden shadow-lg flex flex-col transition-transform transform hover:scale-105 hover:shadow-2xl border border-gray-200 p-6 cursor-pointer">
                      <img
                        src={video.thumbnail}
                        alt={video.title}
                        className="object-cover w-full h-40"
                      />
                      <div className="p-4 flex flex-col flex-grow">
                        <h4 className="font-semibold text-sm text-gray-900 line-clamp-2 mb-2">
                          {video.title}
                        </h4>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getZodiacSymbol = (sign) => {
    if (!sign) return { symbol: '', name: '' };

    const symbols = {
      'Aries': '♈', 'Taurus': '♉', 'Gemini': '♊', 'Cancer': '♋',
      'Leo': '♌', 'Virgo': '♍', 'Libra': '♎', 'Scorpio': '♏',
      'Sagittarius': '♐', 'Capricorn': '♑', 'Aquarius': '♒', 'Pisces': '♓'
    };

    const normalizedSign = sign.charAt(0).toUpperCase() + sign.slice(1).toLowerCase();
    const symbol = symbols[normalizedSign] || normalizedSign;
    return { symbol, name: normalizedSign };
  };

  const getPlanetSymbol = (planet) => {
    if (!planet) return { symbol: '', name: '' };

    const symbols = {
      'Sun': '☉', 'Moon': '☽', 'Mars': '♂', 'Mercury': '☿',
      'Jupiter': '♃', 'Venus': '♀', 'Saturn': '♄', 'Rahu': '☊',
      'Ketu': '☋', 'Uranus': '♅', 'Neptune': '♆', 'Pluto': '♇'
    };

    const normalizedPlanet = planet.charAt(0).toUpperCase() + planet.slice(1).toLowerCase();
    const symbol = symbols[normalizedPlanet] || normalizedPlanet;
    return { symbol, name: normalizedPlanet };
  };

  const DisclaimerModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full mx-auto max-h-[90vh] sm:max-h-[80vh] overflow-y-auto relative"
             style={{ maxWidth: 'min(90vw, 42rem)' }}>
          <div className="sticky top-0 bg-white dark:bg-gray-800 flex justify-between items-start mb-4 pb-2 border-b border-gray-200">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white pr-8">Important Disclaimers</h2>
            <button 
              onClick={onClose} 
              className="absolute right-2 top-0 text-gray-500 hover:text-gray-700 p-1"
            >
              <X size={20} />
            </button>
          </div>
          <div className="space-y-4">
            <div className="p-3 sm:p-4 bg-orange-50 rounded-lg">
              <h3 className="font-semibold text-base sm:text-lg mb-2">Guruji AI Disclaimer</h3>
              <p className="text-sm sm:text-base text-gray-700">
                With utmost respect to Param Pujya Premanand maharaji, this AI model attempts to provide guidance in alignment with his teachings. However, as this is AI-generated content, responses may not always be accurate despite our best efforts to maintain authenticity.
              </p>
            </div>
            <div className="p-3 sm:p-4 bg-purple-50 rounded-lg">
              <h3 className="font-semibold text-base sm:text-lg mb-2">Astrology AI Disclaimer</h3>
              <p className="text-sm sm:text-base text-gray-700">
                Astrology is a profound and complex science. The information provided here is AI-generated and should be considered for entertainment purposes only. For critical decisions, please consult with a qualified astrologer.
              </p>
            </div>
            <div className="p-3 sm:p-4 bg-gray-50 rounded-lg">
              <h3 className="font-semibold text-base sm:text-lg mb-2">General Disclaimer</h3>
              <p className="text-sm sm:text-base text-gray-700">
                The creator of this website takes no responsibility for the accuracy, completeness, or applicability of any outputs generated or questions asked through this platform. Use the information at your own discretion.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toggleVideoPlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsVideoPlaying(true);
      } else {
        videoRef.current.pause();
        setIsVideoPlaying(false);
      }
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      videoContainerRef.current?.requestFullscreen();
      setIsVideoFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsVideoFullscreen(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsVideoFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  return (
    <ThemeProvider>
      <div className="flex flex-col min-h-screen bg-background text-text">
        <Helmet>
          <title>Dedicated to Param Pujaya Premanand Maharaj - Bhajans, Free Astrology & Spiritual Guidance</title>
          <meta name="description" content="Experience divine bhajans and receive free astrology guidance from Param Pujaya Premanand Maharaj. Get your free prashna kundli reading and spiritual advice from an enlightened master." />
          <meta name="keywords" content="bhajans, Premanand Maharaj, free astrology, free prashna kundli, spiritual guidance, ask free advice, divine music, spiritual consultation" />
          <meta property="og:title" content="Param Pujaya Premanand Maharaj - Bhajans & Free Astrology Guidance" />
          <meta property="og:description" content="Experience divine bhajans and receive free astrology guidance from Param Pujaya Premanand Maharaj. Get your free prashna kundli reading today." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        {/* Navbar */}
        <nav className="bg-gradient-to-r from-orange-400 to-yellow-300 text-gray-800 p-4 shadow-lg">
          <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-4 mb-4 md:mb-0">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faOm} className="text-4xl mr-2" />
                <span className="text-2xl font-bold">LiveBhajans</span>
              </div>
              <a href="https://blogs.livebhajans.com" 
                 className="text-gray-800 hover:text-gray-600 font-semibold"
                 target="_blank" 
                 rel="noopener noreferrer">
                Blogs
              </a>
            </div>
            <div className="flex items-center space-x-4">
              <a href="https://buymeacoffee.com/pankaj.pande"
                 className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
                 target="_blank"
                 rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.89 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v-2H4z"/>
                </svg>
                <span>Support Us</span>
              </a>
              <ThemeSwitcher />
              {user ? (
                <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
                  <span className="text-gray-700 text-center md:text-left">
                    <span className="font-semibold">Namaste, {user.displayName}</span>
                    <span className="text-sm block md:inline md:ml-2">
                      (You have created {userTracks} tracks)
                    </span>
                  </span>
                  <button
                    onClick={handleLogout}
                    className="bg-red-500 text-white py-2 px-4 rounded-full hover:bg-red-600"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleLogin}
                  className="bg-green-500 text-white py-2 px-4 rounded-full hover:bg-green-600"
                >
                  {isLoggingIn ? 'Logging in...' : 'Login with Google'}
                </button>
              )}

              <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
                <span className="text-gray-700 font-medium text-center md:text-left">
                  Website Views: {totalViews}
                </span>
                <button
                  className="text-blue-600 hover:text-blue-800 transition-colors duration-200 bg-white rounded-full p-2 shadow-md hover:shadow-lg"
                  onClick={() => setShowInfo(true)}
                >
                  <Info size={20} />
                </button>
                <div className="flex items-center space-x-2 bg-white rounded-full px-2 py-1 shadow-md">
                  <span
                    className={`text-sm font-medium ${
                      language === 'EN' ? 'text-blue-600' : 'text-gray-500'
                    }`}
                  >
                    EN
                  </span>
                  <button
                    onClick={toggleLanguage}
                    className={`w-10 h-5 flex items-center rounded-full p-0.5 duration-300 ease-in-out ${
                      language === 'HI' ? 'bg-blue-600' : 'bg-gray-300'
                    }`}
                  >
                    <div
                      className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                        language === 'HI' ? 'translate-x-5' : ''
                      }`}
                    ></div>
                  </button>
                  <span
                    className={`text-sm font-medium ${
                      language === 'HI' ? 'text-blue-600' : 'text-gray-500'
                    }`}
                  >
                    HI
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nav>

        {/* Show Info Modal */}
        {showInfo && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full mx-auto max-h-[90vh] sm:max-h-[80vh] overflow-y-auto relative"
                 style={{ maxWidth: 'min(90vw, 42rem)' }}>
              <div className="sticky top-0 bg-white dark:bg-gray-800 flex justify-between items-start mb-4 pb-2 border-b border-gray-200">
                <h2 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white pr-8">Livebhajans Story</h2>
                <button 
                  onClick={() => setShowInfo(false)} 
                  className="absolute right-2 top-0 text-gray-500 hover:text-gray-700 p-1"
                >
                  <X size={20} />
                </button>
              </div>
              <div className="space-y-4">
                <div className="p-3 sm:p-4 bg-orange-50 rounded-lg">
                  <h3 className="font-semibold text-base sm:text-lg mb-2">Our Journey</h3>
                  <p className="text-sm sm:text-base text-gray-700">
                    In 2008, a humble soul named Pankaj Pande embarked on a heartfelt mission to share
                    his love for bhajans and spiritual music with the world. ...
                  </p>
                </div>
                <div className="p-3 sm:p-4 bg-purple-50 rounded-lg">
                  <h3 className="font-semibold text-base sm:text-lg mb-2">Our Mission</h3>
                  <p className="text-sm sm:text-base text-gray-700">
                    At LiveBhajans, we are dedicated to preserving and sharing the rich tradition of
                    spiritual music. Our platform serves as a bridge between ancient wisdom and modern
                    technology, making divine music accessible to everyone, everywhere.
                  </p>
                </div>
                <div className="p-3 sm:p-4 bg-gray-50 rounded-lg">
                  <h3 className="font-semibold text-base sm:text-lg mb-2">Join Our Community</h3>
                  <p className="text-sm sm:text-base text-gray-700">
                    We invite you to be part of our growing spiritual family. Whether you're a
                    devotee, a music lover, or someone seeking peace through divine sounds, LiveBhajans
                    welcomes you with open arms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <main className="flex-grow container mx-auto px-4 py-8">


          {/* Disclaimers Section */}
          <div className="w-full">
            {/* Website welcome or instructions */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center mb-12"
            >
              <h2 className="text-4xl font-bold mb-4 text-orange-600">
                {language === 'EN' ? '✨ Welcome to LiveBhajans!' : '✨ लाइव भजन में आपका स्वागत है!'}
              </h2>

              <button
                onClick={() => setDisclaimerModalOpen(true)}
                className="flex items-center justify-center gap-2 mx-auto mb-8 px-4 py-2 bg-orange-100 hover:bg-orange-200 text-orange-800 rounded-full transition-colors duration-200"
              >
                <AlertTriangle size={20} />
                <span>View Important Disclaimers</span>
              </button>

              <button
                onClick={() => window.location.href = 'https://blogs.livebhajans.com'}
                className="flex items-center justify-center gap-2 mx-auto mb-8 px-4 py-2 bg-orange-100 hover:bg-orange-200 text-orange-800 rounded-full transition-colors duration-200"
              >
                
                <span>Checkout Guruji's Teachings, Stories, Message of the day on https://blogs.livebhajans.com</span>
              </button>

              {/* Cards Container */}
              <div className="flex flex-col md:flex-row justify-center items-center gap-6 w-full max-w-4xl mx-auto mb-8">
                {/* Ask Guruji Card */}
                <div
                  className="w-full md:w-80 bg-white rounded-2xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl border border-gray-200 p-6 cursor-pointer"
                  onClick={() => {
                    if (!user) {
                      alert('You must log in to ask Guruji.');
                      return;
                    }
                    setShowGurujiForm(true);
                  }}
                >
                  <div className="flex items-center justify-center mb-4">
                    <FontAwesomeIcon icon={faOm} className="text-4xl text-indigo-600" />
                  </div>
                  <h3 className="text-2xl font-extrabold text-indigo-600 text-center mb-2">
                    Ask Guruji
                  </h3>
                  <p className="text-gray-700 text-center">
                    Seek profound spiritual guidance from our wise Guruji.
                  </p>
                </div>

                {/* Ask Stars Card */}
                <div
                  className="w-full md:w-80 bg-white rounded-2xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl border border-gray-200 p-6 cursor-pointer"
                  onClick={() => {
                    if (!user) {
                      alert('You must log in to ask the stars.');
                      return;
                    }
                    setShowStarsForm(true);
                  }}
                >
                  <div className="flex items-center justify-center mb-4">
                    <FontAwesomeIcon icon={faStar} className="text-4xl text-indigo-600" />
                  </div>
                  <h3 className="text-2xl font-extrabold text-indigo-600 text-center mb-2">
                    Ask Stars
                  </h3>
                  <p className="text-gray-700 text-center">
                    Unveil the secrets of the cosmos and let the stars guide you.
                  </p>
                </div>
              </div>
            </motion.div>


          {/* Demo Video */}
          <motion.div
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.2 }}
  className="mb-12"
>
  <center><h2 className="text-4xl font-bold mb-4 text-orange-600">LiveBhajans Demo</h2></center>

  <div className="flex justify-center">
    <div 
      ref={videoContainerRef} 
      className="rounded-2xl overflow-hidden shadow-2xl relative group w-full md:w-3/4 lg:w-2/3"
    >
      <video
        ref={videoRef}
        className="w-full aspect-video object-cover"
        src="/livebhajans_demo_compressed.mp4"
        autoPlay
        loop
        muted
        playsInline
      />
      
      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
      
      {/* Controls container - hidden on mobile */}
      <div className="absolute inset-0 hidden md:flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black/30">
        <div className="flex gap-8">
          {/* Play/Pause button */}
          <button 
            onClick={toggleVideoPlay} 
            className="p-6 rounded-full text-white backdrop-blur-md shadow-lg transform hover:scale-110 transition-all duration-300"
style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.8)'}
onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}
            aria-label={isVideoPlaying ? 'Pause video' : 'Play video'}
          >
            {isVideoPlaying ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                <path fillRule="evenodd" d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-1.5 0v-2.69l-3.97 3.97a.75.75 0 01-1.06-1.06l3.97-3.97H4.5a.75.75 0 01-.75-.75zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-1.5 0v-2.69l3.97-3.97a.75.75 0 11-1.06-1.06l-3.97 3.97V5.25z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
              </svg>
            )}
          </button>
          
          {/* Fullscreen button */}
          <button 
            onClick={toggleFullscreen} 
            className="p-6 rounded-full text-white backdrop-blur-md shadow-lg transform hover:scale-110 transition-all duration-300"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.8)'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}
            aria-label={isVideoFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
          >
            {isVideoFullscreen ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                <path fillRule="evenodd" d="M3.22 3.22a.75.75 0 011.06 0l3.97 3.97V4.5a.75.75 0 011.5 0V9a.75.75 0 01-.75.75H4.5a.75.75 0 010-1.5h2.69L3.22 4.28a.75.75 0 010-1.06zm17.56 0a.75.75 0 010 1.06l-3.97 3.97h2.69a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v2.69l3.97-3.97a.75.75 0 011.06 0zM3.75 15a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-2.69l-3.97 3.97a.75.75 0 01-1.06-1.06l3.97-3.97H4.5a.75.75 0 01-.75-.75zm10.5 0a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-2.69l3.97 3.97a.75.75 0 11-1.06 1.06l-3.97-3.97V19.5a.75.75 0 01-1.5 0V15z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                <path fillRule="evenodd" d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-3.97 3.97a.75.75 0 11-1.06-1.06l3.97-3.97h-2.69a.75.75 0 01-.75-.75zm-12 0A.75.75 0 013.75 3h4.5a.75.75 0 010 1.5H5.56l3.97 3.97a.75.75 0 01-1.06 1.06L4.5 5.56v2.69a.75.75 0 01-1.5 0v-4.5zm11.47 11.78a.75.75 0 111.06-1.06l3.97 3.97v-2.69a.75.75 0 011.5 0v4.5a.75.75 0 01-.75.75h-4.5a.75.75 0 010-1.5h2.69l-3.97-3.97zm-4.94-1.06a.75.75 0 010 1.06L5.56 19.5h2.69a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v2.69l3.97-3.97a.75.75 0 011.06 0z" clipRule="evenodd" />
              </svg>
            )}
          </button>
        </div>
      </div>
      
      {/* Mobile hint */}
      <div className="absolute bottom-4 right-4 text-white text-sm bg-black/60 px-3 py-2 rounded-full md:hidden">
        Tap to play
      </div>
    </div>
  </div>
</motion.div>


            {/* Top 5 Favorite Bhajans */}
            <div className="w-full mb-8">
              <center><h3 className="text-3xl font-bold mb-4 text-orange-600">Top 5 Favorite Bhajans</h3></center>
              <div className="relative">
                <div className="flex overflow-x-auto space-x-4 pb-4 top-songs">
                  {topSongs.map((song, index) => (
                    <div
                      key={song.id}
                      className="flex-shrink-0 w-64 bg-white p-4 rounded-lg shadow-md top-song-item hover-scale fade-in"
                      style={{ animationDelay: `${index * 0.1}s` }}
                    >
                      <h4 className="text-lg font-semibold">
                        {`${index + 1}. ${song.bhajan_name}`}
                      </h4>
                      <p className="text-gray-600 truncate">
                        Duration: {formatDuration(song.duration)}
                      </p>
                      <p className="text-gray-600 truncate">Likes: {song.likes}</p>
                      <button
                        className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                        onClick={() => {
                          setCurrentTrack(
                            tracks.findIndex((t) => t.id === song.id)
                          );
                          setIsPlaying(true);
                        }}
                      >
                        Play
                      </button>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center mt-2 top-songs-dots">
                  {topSongs.map((_, index) => (
                    <span key={index} className="h-2 w-2 bg-gray-300 rounded-full mx-1"></span>
                  ))}
                </div>
              </div>
            </div>

            <div className="w-full">


              {/* Player Section */}
              {isLoading ? (
                <div className="flex items-center justify-center h-64">
                  <Loader2 className="animate-spin" size={48} />
                </div>
              ) : (
                <div className="w-full">
                  <div
                    id="player-container"
                    className="bg-background rounded-lg shadow-lg p-4 border-4 border-primary relative player-container"
                  >
                    <div className="flex justify-between items-center mb-6">
                      <h2 className="text-2xl font-bold text-orange-600">LiveBhajans Player</h2>
                      <div className="flex space-x-3">
                        <Maximize2
                          size={24}
                          className="text-gray-600 cursor-pointer"
                          onClick={toggleFullScreen}
                        />
                        <ListMusic
                          size={24}
                          className="text-gray-600 cursor-pointer"
                          onClick={() => setShowTracks(!showTracks)}
                        />
                        <Repeat
                          size={24}
                          className={`cursor-pointer ${
                            repeat ? 'text-orange-500' : 'text-gray-600'
                          }`}
                          onClick={toggleRepeat}
                        />
                        <Shuffle
                          size={24}
                          className={`cursor-pointer ${
                            shuffle ? 'text-orange-500' : 'text-gray-600'
                          }`}
                          onClick={toggleShuffle}
                        />
                      </div>
                    </div>

                    <div className="player-content flex flex-col md:flex-row items-center md:items-start">
                      <img
                        src={tracks[currentTrack]?.cover_image || '/bhajan-cover.jpg'}
                        alt="Create your own awesome bhajans with LiveBhajans"
                        className="player-image w-24 h-24 md:w-32 md:h-32 object-cover rounded-lg mr-0 md:mr-6 mb-4 md:mb-0"
                      />
                      <div className="player-info text-center md:text-left">
                        <h3 className="text-xl font-semibold">
                          {formattedTrack.index + 1}. {formatBhajanName(formattedTrack.bhajan_name)}
                        </h3>
                        <p className="text-lg text-gray-600">
                          Genre: {formattedTrack.genre || 'N/A'}
                        </p>
                        <span className="text-sm text-gray-600 block">
                          Created By: {formattedTrack.user_name} (
                          {maskEmail(formattedTrack.user_email)})
                        </span>

                        <span className="text-sm text-gray-600 block">
                          Created:{' '}
                          {formattedTrack.created_at
                            ? new Date(formattedTrack.created_at).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                            : 'N/A'}
                        </span>
                        <div className="flex items-center space-x-2 mt-2">
                          <span title="Total Likes for this Track">
                            {formattedTrack.likes} <span
                              title="Total Likes for this Track"
                              role="img"
                              aria-label="likes"
                              className="cursor-pointer"
                              onClick={() => handleLike(formattedTrack.id)}
                            >
                              ❤️
                            </span>
                          </span>
                          <span title="Total Track Views">
                            {formattedTrack.views}{' '}
                            <span
                              role="img"
                              aria-label="views"
                              title="Total Track Views"
                            >
                              👁️
                            </span>
                          </span>
                          <span title="Total time track has been played">
                            {formatDuration(formattedTrack.total_played_time)}
                            <span role="img" aria-label="time played">
                              ⌛
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Audio Progress/Seek */}
                    <div className="mb-6">
                      <input
                        id="seek-bar"
                        type="range"
                        min={0}
                        max={duration}
                        value={currentTime}
                        onChange={handleSeek}
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        style={{
                          background: `linear-gradient(to right, #f97316 ${
                            (currentTime / duration) * 100
                          }%, #e5e7eb ${(currentTime / duration) * 100}%)`,
                        }}
                      />
                      <div className="flex justify-between text-sm mt-2">
                        <span>{formatDuration(currentTime)}</span>
                        <span>{formatDuration(duration)}</span>
                      </div>
                    </div>

                    {/* Player Controls */}
                    <div className="flex justify-between items-center mb-6">
                      <SkipBack
                        size={32}
                        className="text-gray-600 cursor-pointer hover-scale"
                        onClick={handleSkipBack}
                      />
                      <div
                        className="w-16 h-16 bg-primary rounded-full flex items-center justify-center cursor-pointer hover-scale ripple"
                        onClick={() => setIsPlaying(!isPlaying)}
                      >
                        {isPlaying ? (
                          <Pause size={32} className="text-grey" />
                        ) : (
                          <Play size={32} className="text-grey ml-1" />
                        )}
                      </div>
                      <SkipForward
                        size={32}
                        className="text-gray-600 cursor-pointer hover-scale"
                        onClick={handleSkipForward}
                      />
                    </div>

                    {/* Volume */}
                    <div className="flex items-center space-x-3 mb-6">
                      {isMuted ? (
                        <VolumeX size={24} className="cursor-pointer" onClick={() => setIsMuted(false)} />
                      ) : (
                        <Volume2 size={24} className="cursor-pointer" onClick={() => setIsMuted(true)} />
                      )}
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={isMuted ? 0 : volume}
                        onChange={handleVolumeChange}
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        style={{
                          background: `linear-gradient(to right, #f97316 ${
                            isMuted ? 0 : volume
                          }%, #e5e7eb ${isMuted ? 0 : volume}%)`,
                        }}
                      />
                    </div>

                    {/* Search */}
                    <div className="mb-6">
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Search tracks..."
                          className="w-full p-3 pl-10 rounded-lg bg-gray-100 text-gray-900"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Search className="absolute left-3 top-3 text-gray-600" size={20} />
                      </div>
                    </div>

                    {/* Category Cloud */}
                    <div className="mb-6">
                      <h4 className="text-xl font-semibold mb-2">
                        Categories (Total Tracks: {totalTracks})
                      </h4>
                      <div className="categories flex flex-wrap gap-2">
                        {Object.keys(categorizedTracks).map((category) => (
                          <button
                            key={category}
                            className="category-button px-4 py-2 rounded-full border bg-gray-200 text-gray-800 hover:bg-primary hover:text-white transition-colors duration-200 hover-scale ripple"
                            onClick={() => toggleCategory(category)}
                          >
                            {category}
                            {selectedCategory === category && <X size={16} className="ml-2 inline" />}
                          </button>
                        ))}
                      </div>
                    </div>

                    {showTracks && (
                      <div className="max-h-96 overflow-y-auto" id="playlist-container">
                        <div className="track-list space-y-2">
                          {filteredTracks.map((track) => (
                            <div
                              key={track.id}
                              ref={(el) => (scrollRefs.current[track.index] = el)}
                              className={`flex flex-col md:flex-row items-start justify-between py-2 px-4 hover:bg-gray-100 rounded cursor-pointer ${
                                currentTrack === track.index ? 'bg-green-100' : ''
                              } slide-in`}
                              style={{ animationDelay: `${track.index * 0.05}s` }}
                              onClick={() => {
                                setCurrentTrack(track.index);
                                setIsPlaying(true);
                              }}
                            >
                              <div className="flex items-center space-x-2">
                                <span>{track.index + 1}</span>
                                <div>
                                  <p className="font-medium">
                                    {formatBhajanName(track.bhajan_name)}
                                  </p>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setExpandedTracks((prevState) => ({
                                        ...prevState,
                                        [track.index]: !prevState[track.index],
                                      }));
                                    }}
                                    style={{
                                      backgroundColor: 'blue',
                                      color: 'white',
                                      border: 'none',
                                      padding: '5px 10px',
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                      marginTop: '5px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {expandedTracks[track.index] ? 'Hide Info' : 'Track Info'}
                                  </button>
                                  {expandedTracks[track.index] && (
                                    <div>
                                      <p className="text-sm text-gray-600">
                                        Genre: {track.genre}
                                      </p>
                                      <p className="text-sm text-gray-600">
                                        Instruments: {track.instruments}
                                      </p>
                                      <p className="text-sm text-gray-600">
                                        Vocals: {track.vocals}
                                      </p>
                                      <p className="text-sm text-gray-600">BPM: {track.bpm}</p>
                                      <p className="text-sm text-gray-600">
                                        Created By: {track.user_name} ({maskEmail(track.user_email)})
                                      </p>
                                      <span className="text-sm text-gray-600 block">
                                        Created Date:{' '}
                                        {track.created_at
                                          ? new Date(track.created_at).toLocaleString('en-US', {
                                              year: 'numeric',
                                              month: 'long',
                                              day: 'numeric',
                                              hour: '2-digit',
                                              minute: '2-digit',
                                            })
                                          : 'N/A'}
                                      </span>
                                      <span className="text-sm text-gray-600 flex items-center">
                                        <WhatsappShareButton
                                          url={`${window.location.origin}/audio/${track.filename}`}
                                          className="mr-2"
                                        >
                                          <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                        <FacebookShareButton
                                          url={`${window.location.origin}/audio/${track.filename}`}
                                          className="mr-2"
                                        >
                                          <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                          url={`${window.location.origin}/audio/${track.filename}`}
                                        >
                                          <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="flex items-center space-x-2">
                                <span title="Total Likes for this Track">
                                  {Math.round(track.likes)}{' '}
                                  <span
                                    title="Total Likes for this Track"
                                    role="img"
                                    aria-label="likes"
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleLike(track.id);
                                    }}
                                  >
                                    ❤️
                                  </span>
                                </span>
                                <span title="Total Track Views">
                                  {Math.round(track.views)}{' '}
                                  <span title="Total Track Views" role="img" aria-label="views">
                                    👁️
                                  </span>
                                </span>
                                <span title="Total time track has been played">
                                  {formatDuration(track.total_played_time)}
                                  <span role="img" aria-label="views">
                                    ⌛
                                  </span>
                                </span>
                                <span>{formatDuration(track.duration || 0)}</span>
                                {currentTrack === track.index && isPlaying ? (
                                  <Pause
                                    size={20}
                                    className="text-orange-500 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsPlaying(false);
                                    }}
                                  />
                                ) : (
                                  <Play
                                    size={20}
                                    className="text-gray-600 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setCurrentTrack(track.index);
                                      setIsPlaying(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>

        {/* FAQ Section */}
        <section className="bg-gray-50 py-12 px-4 mt-8">
          <div className="container mx-auto max-w-4xl">
            <h2 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions</h2>
            <div className="space-y-6">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">Is LiveBhajans free to use?</h3>
                <p className="text-gray-600">Yes, LiveBhajans is completely free to use. We believe in making spiritual music and guidance accessible to everyone without any cost.</p>
              </div>
              
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">Who is this website dedicated to?</h3>
                <p className="text-gray-600">This website is dedicated to Param Pujya Premanand Maharaj, a revered spiritual master who has touched countless lives through his divine teachings and spiritual guidance.</p>
              </div>
              
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">What is the mission of LiveBhajans?</h3>
                <p className="text-gray-600">Our mission is to spread spiritual awareness and divine knowledge through bhajans, while providing free astrological guidance and spiritual consultations to seekers worldwide.</p>
              </div>
              
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">What services do you offer?</h3>
                <p className="text-gray-600">We offer free access to divine bhajans, free astrology consultations, prashna kundli readings, and spiritual guidance from Param Pujya Premanand Maharaj's teachings.</p>
              </div>
              
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">How can I support LiveBhajans?</h3>
                <p className="text-gray-600">You can support our mission by spreading the word, sharing our content, and if you wish, contributing through our <a href="https://buymeacoffee.com/pankaj.pande"
                 className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2"
                 target="_blank"
                 rel="noopener noreferrer">Support Us</a> page to help us maintain and improve our services.</p>
              </div>
              
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">How often is new content added?</h3>
                <p className="text-gray-600">We regularly update our collection of bhajans and spiritual content. New bhajans are added weekly, and our blog is updated with fresh spiritual insights and guidance.</p>
              </div>
            </div>
          </div>
        </section>

        <footer className="bg-gradient-to-r from-orange-500 to-yellow-500 text-black p-4 mt-8">
  <div className="container mx-auto text-center">
    <nav className="mb-2">
      <a href="/terms" className="text-black hover:underline mx-2">
        Terms &amp; Conditions
      </a>
      <a href="/privacy" className="text-black hover:underline mx-2">
        Privacy Policy
      </a>
    </nav>
    <p>
      &copy; 2025 LiveBhajans. All rights reserved. Made with <span className="text-red-500">&hearts;</span> by Pankaj Pande.
    </p>
  </div>
</footer>

        {/* Show the newly generated tracks dialog */}
        <GeneratedTracksDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          trackUrl1={generatedTrackUrl}
          trackUrl2={generatedTrackUrl2}
          trackName1={generatedTrackName1}
          trackName2={generatedTrackName2}
          trackGenre1={generatedTrackGenre1}
          trackGenre2={generatedTrackGenre2}
          trackInstruments1={generatedTrackInstruments1}
          trackInstruments2={generatedTrackInstruments2}
          creationDuration={creationDuration}
        />

        {/* Guruji Form Modal */}
        {showGurujiForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
              <div className="p-6">
                <h3 className="text-2xl font-bold mb-4 text-indigo-600">Ask Guruji</h3>
                <div className="text-sm text-gray-600 bg-orange-50 p-3 rounded-lg">
                  <strong>Note:</strong> AI-generated responses based on Param Pujya Premanand maharaji's teachings. For authentic guidance, please consult direct sources.
                </div>
                <textarea
                  className="w-full h-32 p-4 border rounded-lg mb-4 focus:ring-2 focus:ring-indigo-500"
                  placeholder="Type your spiritual question here..."
                  value={gurujiQuestion}
                  onChange={(e) => setGurujiQuestion(e.target.value)}
                ></textarea>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => {
                      setShowGurujiForm(false);
                      setGurujiQuestion('');
                    }}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleGurujiSubmit}
                    className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                  >
                    Ask
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Stars Form Modal */}
        {showStarsForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
              <div className="p-6">
                <h3 className="text-2xl font-bold mb-4 text-indigo-600">Ask Stars</h3>
                <div className="text-sm text-gray-600 bg-purple-50 p-3 rounded-lg">
                  <strong>Note:</strong> Astrology is a profound and complex science. The information provided here is AI-generated and should be considered for entertainment purposes only. For critical decisions, please consult with a qualified astrologer.
                </div>



                <textarea
                  className="w-full h-32 p-4 border rounded-lg mb-4 focus:ring-2 focus:ring-indigo-500"
                  placeholder="Ask about your cosmic journey..."
                  value={starsQuestion}
                  onChange={(e) => setStarsQuestion(e.target.value)}
                ></textarea>


{/* LOCATION FIELD */}
<input
    className="w-full p-4 border rounded-lg mb-4 focus:ring-2 focus:ring-indigo-500"
    type="text"
    placeholder="Enter your location.. (required!)"
    value={locationInput}
    onChange={(e) => handleLocationChange(e.target.value)}
  />

  {/* Show suggestions if we have any */}
  {locationSuggestions.length > 0 && (
    <div className="bg-white border rounded-lg shadow max-h-48 overflow-y-auto">
      {locationSuggestions.map((suggestion, index) => (
        <div
          key={index}
          className="p-2 hover:bg-gray-100 cursor-pointer"
          onClick={() => handleSelectLocation(suggestion)}
        >
          {suggestion.display_name}
        </div>
      ))}
    </div>
  )}


                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => {
                      setShowStarsForm(false);
                      setStarsQuestion('');
                    }}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAstroSubmit}
                    className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                  >
                    Ask
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Loading Progress Dialog */}
        {astroLoading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 flex flex-col items-center">
              <div className="w-16 h-16 mb-4">
                <CircularProgressbar
                  value={100}
                  strokeWidth={8}
                  styles={buildStyles({
                    strokeLinecap: 'round',
                    pathColor: '#4f46e5',
                    trailColor: '#e5e7eb',
                  })}
                />
              </div>
              <p className="text-lg font-semibold text-gray-700">{astroProgressText}</p>
            </div>
          </div>
        )}

        {gurujiLoading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 flex flex-col items-center">
              <div className="w-16 h-16 mb-4">
                <CircularProgressbar
                  value={100}
                  strokeWidth={8}
                  styles={buildStyles({
                    strokeLinecap: 'round',
                    pathColor: '#4f46e5',
                    trailColor: '#e5e7eb',
                  })}
                />
              </div>
              <p className="text-lg font-semibold text-gray-700">{gurujiProgressText}</p>
            </div>
          </div>
        )}

        {/* Disclaimer Modal */}
        {disclaimerModalOpen && (
          <DisclaimerModal isOpen={disclaimerModalOpen} onClose={() => setDisclaimerModalOpen(false)} />
        )}

        {/* Audio element */}
        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleTimeUpdate}
          onEnded={async () => {
            const trackId = tracks[currentTrack]?.id;
            const trackDuration = tracks[currentTrack]?.duration;
            if (trackId && trackDuration) {
              await updatePlayedDuration(trackId, trackDuration);
              setViews(views + 1);
            }
            if (repeat) {
              audioRef.current.currentTime = 0;
              setTimeout(() => {
                audioRef.current
                  .play()
                  .catch((error) => console.error('Error restarting audio:', error));
              }, 0);
            } else if (shuffle) {
              const nextTrackIndex = getRandomTrackIndex();
              setCurrentTrack(nextTrackIndex);
            } else {
              setCurrentTrack((prev) => (prev + 1) % filteredTracks.length);
              setIsPlaying(true);
              setTimeout(() => {
                audioRef.current.play().catch((error) => {
                  console.error('Error starting audio:', error);
                });
              }, 0);
            }
          }}
          onPause={() => {
            setIsPlaying(false);
            setCurrentTime(audioRef.current.currentTime);
          }}
          onPlay={async () => {
            setIsPlaying(true);
            const trackId = tracks[currentTrack]?.id;
            const trackDuration = tracks[currentTrack]?.duration;
            if (trackId && trackDuration) {
              await recordPlay(trackId, trackDuration);
              setViews(views + 1);
            }
          }}
          src={tracks[currentTrack]?.filename ? `/audio/${tracks[currentTrack]?.filename}` : ''}
        />

        {/* Guruji & Astro modals for final response */}
        <AstroResponseModal />
        <GurujiResponseModal />

        {playAfterInteraction && <PlayDialog onClose={handlePlayClick} />}
      </div>
    </ThemeProvider>
  );
};

export default BhajansWebsite;
