// TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms and Conditions</h1>
        
        <div className="space-y-6 text-gray-600">
          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">1. Acceptance of Terms</h2>
            <p>By accessing and using LiveBhajans.com, you accept and agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">2. Service Description</h2>
            <p>LiveBhajans.com provides a platform for accessing and listening to spiritual bhajans and devotional music. Our service includes AI-generated bhajan variations and traditional bhajan recordings.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">3. User Responsibilities</h2>
            <ul className="list-disc pl-5 space-y-2">
              <li>You must be at least 13 years old to use this service</li>
              <li>You agree to provide accurate information when required</li>
              <li>You are responsible for maintaining the confidentiality of your account</li>
              <li>You agree not to misuse or attempt to gain unauthorized access to our service</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">4. Intellectual Property</h2>
            <p>All content on LiveBhajans.com, including but not limited to AI-generated bhajans, text, graphics, logos, and software, is the property of LiveBhajans or its content suppliers and is protected by copyright laws.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">5. Limitations of Liability</h2>
            <p>LiveBhajans.com and its operators will not be liable for any damages arising from the use or inability to use our services. We do not guarantee uninterrupted or error-free service.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">6. Changes to Terms</h2>
            <p>We reserve the right to modify these terms at any time. Continued use of the service after changes constitutes acceptance of new terms.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">7. Contact Information</h2>
            <p>For any questions regarding these terms, please contact us through our website.</p>
          </section>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-200 text-sm text-gray-500">
          Last updated: February 22, 2025
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;